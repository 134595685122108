<template>
  <modal
    name="edituserDetails"
    class="connection-modal"
    :scrollable="true"
    :height="'auto'"
    @closed="hideWelcomePage"
    :clickToClose="false"
  >
    <div class="">
      <div class="w-100">
        <div class="row" style="padding-top: 50px;">
          <div class="col p-0 welcome ml-4 mt-4 align-start">
            <img src="../../assets/group-21.png" alt class="welcome--image" />
          </div>

          <div class="col p-0 intro__content">
            <p class="Welcome-to-Data-Poem ProximaNovaRegular">
              {{ this.modalTitle }}
            </p>
            <div
              class="Together-let-us-hel ProximaNovaRegular mt-0 "
              style="height: auto; width: 80% !important;"
            >
              <b-form-input
                type="text"
                class="form-control input-control c-form-input m-0  w-100 mb-5"
                id="exampleInputEmail1"
                aria-describedby="text"
                placeholder="Full Name*"
                name="example-input-1"
                v-model="userName"
              ></b-form-input>
              <b-form-input
                type="text"
                class="form-control input-control c-form-input m-0 w-100 mb-5"
                id="exampleInputEmail2"
                aria-describedby="emailHelp"
                placeholder="Enter Email*"
                name="example-input-1"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                disabled
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback" class="w-100"
                >Invalid Email.</b-form-invalid-feedback
              >
              <div class="w-100 mb-5" style="background-color:#eff5ff">
                <w-white-dropdown
                  :options="roleDropdown"
                  :selectedOption="selectedRole"
                  :labelText="'Role*'"
                  class="w-100"
                  @input="getSelectRole($event)"
                ></w-white-dropdown>
              </div>
              <div class="w-100 mb-5" style="background-color:#eff5ff">
                <w-tree-dropdown
                  :placeHolder="'Select Brands'"
                  class="mb-5"
                  :rootOptions="brandList"
                  :selectedValue="selectedBrand"
                  @selectedField="getSelectedBrand($event)"
                ></w-tree-dropdown>
              </div>
              <div class="w-100 mb-5" style="background-color:#eff5ff">
                <w-tree-dropdown
                  :placeHolder="'Select Tools'"
                  class="mb-5"
                  :rootOptions="toolsList"
                  :selectedValue="selectedTools"
                  @selectedField="getSelectedTools($event)"
                ></w-tree-dropdown>
              </div>

              <div class="d-flex my-5 py-4">
                <w-button
                  class="cancel mr-4 px-5"
                  :buttonText="'Cancel'"
                  :isDisable="false"
                  :buttonLoader="'none'"
                  @buttonClicked="hideWelcomePage"
                >
                </w-button>

                <w-button
                  :buttonText="'Update User'"
                  :isDisable="buttonDisable"
                  :class="{ resetPasswordLink: buttonDisable }"
                  @buttonClicked="letsStart"
                  :buttonLoader="buttonLoaderAddConnection"
                >
                </w-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Button from "@/components/Profile/Button.vue";
import WhiteDropdown from "@/widgets/Dropdown.vue";
import { validationMixin } from "vuelidate";
import { formMixin } from "@/mixins/form-mixin.js";
import { required, email } from "vuelidate/lib/validators";
import Treeselect from "@/widgets/MultiSelectDropdown.vue";
export default {
  name: "EditUserModal",
  components: {
    "w-tree-dropdown": Treeselect,
    "w-button": Button,
    "w-white-dropdown": WhiteDropdown,
  },
  props: {},
  mixins: [validationMixin, formMixin],
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  data() {
    return {
      brandList: [],
      toolsList: [],
      selectedBrand: [],
      selectedTools: [],
      userName: "",
      userId: "",
      form: { email: "" },
      buttonLoaderAddConnection: "normal",
      modalTitle: "",
      roleDropdown: [],
      selectedRole: {},
    };
  },
  computed: {
    buttonDisable() {
      if (
        this.form.email !== "" &&
        !this.$v.form.email.$error &&
        this.userName !== "" &&
        this.selectedRole !== {} &&
        this.buttonLoaderAddConnection === "normal"
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    getSelectRole(e) {
      if (e === "Select") {
        if (e === this.selectedRole) {
          return;
        }
        return;
      }
      this.selectedRole = e;
    },
    getSelectedBrand(e) {
      this.selectedBrand = e;
    },
    getSelectedTools(e) {
      this.selectedTools = e;
    },
    open(data) {
      this.userName = "";
      this.form.email = "";
      this.selectedBrand = [];
      this.selectedTools = [];
      if (data.data.role === "USER") {
        this.selectedRole = { id: "user", text: "User" };
      } else if (data.data.role === "ADMIN") {
        this.selectedRole = { id: "admin", text: "Admin" };
      }
      this.buttonLoaderAddConnection = "normal";
      this.modalTitle = "";
      this.toolsList = [];
      this.brandList = [];
      this.roleDropdown = [];
      this.modalTitle = data.title;
      this.toolsList = data.tools;
      this.brandList = data.brand;
      this.roleDropdown = data.role;
      this.form.email = data.data.email;
      this.userName = data.data.userName;
      this.selectedTools = data.selectedTools;
      this.selectedBrand = data.selectedBrand;
      this.userId = "";
      this.userId = data.data.id;
      this.$modal.show("edituserDetails");
    },
    hideWelcomePage() {
      this.$modal.hide("edituserDetails");
    },
    letsStart() {
      var data = {
        userName: this.userName,
        email: this.form.email,
        role: this.selectedRole.id.toUpperCase(),
        id: this.userId,
        brands: this.selectedBrand,
        subscriberToolType: this.selectedTools,
      };
      this.buttonLoaderAddConnection = "loader";
      this.$emit("updateUser", data);
    },
  },
};
</script>
<style>
.connection-modal .vm--modal {
  width: 75% !important;
  box-shadow: rgb(201 206 213) 0px 0px 8px 0px;
  margin: auto;
  display: block;
  top: 0 !important;
  left: auto !important;
}
.form-control > input:focus {
  background: #eff5ff !important;
}
</style>
<style lang="css" scoped>
span.start-from-fresh.mt-4 {
  width: 100%;
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  float: left;
  cursor: pointer;
}
.cancel.button.update {
  background-color: transparent;
  color: #050505;
}
.cancel.button.update:hover {
  color: #fff !important;
}
#exampleInputEmail2:hover {
  color: #050505;
}
#exampleInputEmail1,
#exampleInputEmail2 {
  box-shadow: none;
  background-color: #eff5ff;
  border: 0px;
  font-weight: 500;
}
#exampleInputEmail2 {
  width: 378px;
  height: 60px;
  margin: 53px 1px 40px 30px;
  object-fit: contain;
  color: #9aafd4;
  font-family: ProximaNovaRegular;
  font-size: 16px;

  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 900px) {
  p.Welcome-to-Data-Poem.ProximaNovaRegular {
    text-align: center;
  }
  p.Together-let-us-hel.ProximaNovaRegular.mt-0.w-75 {
    margin-left: 50px;
  }
  button.btn-effect-intro {
    margin-left: 50px;
  }
}
</style>
